import { SnackbarKey, SnackbarProvider, closeSnackbar } from 'notistack';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';

type Props = {
  children: React.ReactNode;
};

export const Providers = ({ children }: Props) => {
  return (
    <SnackbarProvider
      maxSnack={5}
      action={(key: SnackbarKey) => (
        <IconButton onClick={() => closeSnackbar(key)}>
          <ClearIcon fontSize="small" sx={{ color: ({ palette }) => palette.common.white }} />
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  );
};
