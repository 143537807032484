import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ModalWidth, getWidth } from './helpers';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: React.ReactNode;
  modalWidth?: ModalWidth;
  dialogContentWidth?: string;
};

export const Modal = ({
  isOpen,
  onClose,
  title,
  children,
  modalWidth,
  dialogContentWidth = 'auto',
}: Props) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '.MuiPaper-root': {
          overflowY: 'visible',
          maxWidth: '100%',
          alignItems: 'center',
          width: getWidth(modalWidth),
        },
        '.MuiDialogContent-root': { padding: 6 },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: -36,
          top: -12,
          color: ({ palette }) => palette.grey[100],
        }}
      >
        <CloseIcon />
      </IconButton>

      {title && <DialogTitle>{title}</DialogTitle>}

      <DialogContent sx={{ width: dialogContentWidth }}>{children}</DialogContent>
    </Dialog>
  );
};
