import { Box, Button, Typography } from '@mui/material';
import { Modal } from '../Modal/Modal';

type Props = {
  isVisible: boolean;
  onClose: () => void;
  ticketId: string | null;
};

export const HelpdeskSuccessModal = ({ isVisible, onClose, ticketId }: Props) => {
  return (
    <Modal isOpen={isVisible} onClose={onClose}>
      <Box maxWidth={380}>
        <Typography variant="subtitle1" sx={{ fontWeight: '600' }}>
          Заявка {!ticketId ? '' : `№${ticketId}`} успешно отправлена
        </Typography>
        <Box mt={2}>
          <Typography variant="body1">
            Спасибо за Вашу заявку. Мы ценим обратную связь и постараемся рассмотреть заявку как
            можно скорее.
          </Typography>
        </Box>

        <Box mt={3} sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Button
            variant="contained"
            sx={{ textTransform: 'none', fontSize: '1rem' }}
            onClick={onClose}
          >
            Хорошо
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
