import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { HelpdeskForm, HelpdeskFormTypes } from './components/HelpdeskForm/HelpdeskForm';
import { Box, Button, Container } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { createTickets } from './actions/helpdesk/createTickets';
import { UNKNOWN_MESSAGE } from './constants';
import { HelpdeskSuccessModal } from './components/HelpdeskForm/HelpdeskSuccessModal';
import { enqueueSnackbar } from 'notistack';
import { getRecaptcha } from './actions/recaptcha/getRecaptcha';

function App() {
  const ticketIdRef = useRef<string | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [isSuccessModalVisible, setSuccessModalVisible] = useState(false);

  const [searchParams] = useSearchParams();
  const user_id = searchParams.get('user_id');
  const device_id = searchParams.get('device_id');
  const app_version = searchParams.get('app_version');
  const device_info = searchParams.get('device_info');
  const os_info = searchParams.get('os_info');
  const browser_info = searchParams.get('browser_info');
  const success_redirect_url = searchParams.get('success_redirect_url');

  const closePageHandler = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.click();
  };

  const onCreateHelpdeskTicket = async (
    { body, attachments, contact_info }: HelpdeskFormTypes,
    { reset }: UseFormReturn<HelpdeskFormTypes>,
  ) => {
    setLoading(true);
    const recaptchaToken = await getRecaptcha();

    try {
      const res = await createTickets({
        title: body.slice(0, 50) + '...',
        body,
        attachments:
          attachments.data && attachments.filename && attachments['mime-type'] ? [attachments] : [],
        contact_info,
        meta: {
          user_id: user_id ?? '',
          device_id: device_id ?? '',
          app_version: app_version ?? '',
          device_info: device_info ?? '',
          os_info: os_info ?? '',
          browser_info: browser_info ?? '',
        },
        gRecaptchaVerify: recaptchaToken,
      });

      if ('error' in res) {
        enqueueSnackbar({ message: 'Ошибка при отправке заявки', variant: 'error' });
      } else {
        ticketIdRef.current = res.id;
        setSuccessModalVisible(true);
        reset();
      }
    } catch (error) {
      enqueueSnackbar({ message: UNKNOWN_MESSAGE, variant: 'error' });
      console.warn(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <HelpdeskForm
        onSubmit={onCreateHelpdeskTicket}
        formControls={
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }} mt={3}>
            <Button
              variant="contained"
              sx={{ textTransform: 'none', fontSize: '1rem' }}
              type="submit"
              disabled={isLoading}
            >
              Отправить
            </Button>
            {success_redirect_url && (
              <Button
                variant="outlined"
                sx={{ textTransform: 'none', fontSize: '1rem' }}
                onClick={() => closePageHandler(success_redirect_url)}
              >
                Отмена
              </Button>
            )}
          </Box>
        }
      />

      <HelpdeskSuccessModal
        ticketId={ticketIdRef.current}
        isVisible={isSuccessModalVisible}
        onClose={() => {
          setSuccessModalVisible(false);
          if (success_redirect_url) {
            closePageHandler(success_redirect_url);
          }
        }}
      />
    </Container>
  );
}

export default App;
