import { Box, Tooltip } from '@mui/material';
import { QuestionIcon } from '../../icons/QuestionIcon';

type Props = {
  label: React.ReactNode;
  title: React.ReactNode;
};

export const QuestionTooltip = ({ label, title }: Props) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {label}
      <Tooltip title={title} placement="top-start" arrow>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
        >
          <QuestionIcon fontSize="small" />
        </Box>
      </Tooltip>
    </Box>
  );
};
