'use client';

import { Box, Typography } from '@mui/material';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { FormInput } from '../formAdapters/FormInput/FormInput';
import { FormTextareaAutosize } from '../formAdapters/FormTextareaAutosize/FormTextareaAutosize';
import { HelpdeskFileUpload } from './HelpdeskFileUpload/HelpdeskFileUpload';
import { QuestionTooltip } from '../QuestionTooltip/QuestionTooltip';
import { MAX_TEXT_LENGTH_2000, REQUIRED_MESSAGE } from '../../constants';
import { AttachmentInfo } from '../../actions/helpdesk/types';

export type HelpdeskFormTypes = {
  body: string;
  attachments: AttachmentInfo;
  contact_info: string;
};

type Props = {
  onSubmit: (values: HelpdeskFormTypes, methods: UseFormReturn<HelpdeskFormTypes>) => void;
  formControls: React.ReactNode;
};

export const HelpdeskForm = ({ onSubmit, formControls }: Props) => {
  const methods = useForm<HelpdeskFormTypes>({
    defaultValues: {
      body: '',
      attachments: {
        filename: '',
        data: '',
        'mime-type': '',
      },
      contact_info: '',
    },
  });

  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((values) => onSubmit(values, methods))}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: { xs: '100%', sm: 540 },
        }}
      >
        <Box>
          <Box mb={3}>
            <Typography variant="h5">Форма обратной связи</Typography>
          </Box>

          <FormTextareaAutosize
            name="body"
            label="Опишите вашу проблему *"
            rules={{
              required: { value: true, message: REQUIRED_MESSAGE },
              validate: (val: string) =>
                (val.trim() === '' ? REQUIRED_MESSAGE : true) && val.length <= MAX_TEXT_LENGTH_2000,
            }}
            formControlMargin="none"
            minRows={10}
            maxRows={15}
            multilinePlaceholder={<>Опишите шаги, которые вызвали проблему</>}
            maxTextLength={MAX_TEXT_LENGTH_2000}
          />

          <HelpdeskFileUpload name="attachments.data" label="Вложение" />

          <FormInput
            name="contact_info"
            label={
              <QuestionTooltip
                label="Контактные данные"
                title="Позволят нашему специалисту связаться с вами при необходимости уточнения деталей"
              />
            }
            type="text"
            size="small"
            placeholder="Введите email или номер телефона"
          />
        </Box>

        {formControls}
      </Box>
    </FormProvider>
  );
};
