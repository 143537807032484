import imageCompression, { Options } from 'browser-image-compression';

export const compressImage = async (imageFile: File) => {
  const options: Options = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
    initialQuality: 0.8,
  };

  try {
    const compressedFile = await imageCompression(imageFile, options);

    return new File([compressedFile], compressedFile.name, {
      lastModified: new Date().getTime(),
      type: compressedFile.type,
    });
  } catch (error) {
    console.warn(error);
  }
};
