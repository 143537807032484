import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Typography } from '@mui/material';
import { grey, red } from '@mui/material/colors';

type Props = {
  name: string;
  textLength: number;
  maxTextLength: number;
  isError: boolean;
};

export const CharacterCounter = ({ name, textLength, maxTextLength, isError }: Props) => {
  const {
    formState: { errors },
    setError,
    clearErrors,
  } = useFormContext();

  useEffect(() => {
    if (textLength > maxTextLength) {
      setError(name, { type: 'maxTextLength' });
    } else {
      if (errors[name] && errors[name]?.type === 'maxTextLength') {
        clearErrors(name);
      }
    }
  }, [clearErrors, errors, maxTextLength, name, setError, textLength]);

  return (
    <Typography variant="body2" sx={{ color: isError ? red[400] : grey[400] }}>
      {textLength}/{maxTextLength} символов
    </Typography>
  );
};
