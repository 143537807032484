export type ModalWidth = 'sm' | 'md' | 'lg';

export const getWidth = (modalWidth?: ModalWidth) => {
  switch (modalWidth) {
    case 'sm':
      return 420;
    case 'md':
      return 580;
    case 'lg':
      return 800;

    default:
      return 'auto';
  }
};
