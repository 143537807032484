import { useEffect, useState } from 'react';
import { Box, FormControl, FormHelperText, FormControlProps, Typography } from '@mui/material';
import { useFormContext, useController, UseControllerProps } from 'react-hook-form';

import { UploadButton } from '../../UploadButton/UploadButton';
import {
  MAX_UPLOAD_SIZE,
  MAX_UPLOAD_SIZE_MESSAGE,
  UPLOAD_FILE_ERROR_MESSAGE,
} from '../../../constants';
import { compressImage } from '../../UploadButton/compressImage';
import { fileToBase64 } from '../../UploadButton/fileToBase64';

type Props = {
  name: string;
  label?: string;
  defaultValue?: string;
  rules?: UseControllerProps['rules'];
  formControlMargin?: FormControlProps['margin'];
};

export const HelpdeskFileUpload = ({
  name,
  label,
  defaultValue,
  rules,
  formControlMargin = 'normal',
}: Props) => {
  const [isFileUploaded, setFileUploaded] = useState<string | null>(null);

  const { control, setError, clearErrors, setValue } = useFormContext();

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  useEffect(() => {
    if (!isFileUploaded) return;

    if (!field.value) {
      setFileUploaded(null);
    }
  }, [field.value, isFileUploaded]);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (!files?.length) return;
    clearErrors(name);
    let file = files[0];

    try {
      if (file.size > MAX_UPLOAD_SIZE) {
        field.onChange('');
        return setTimeout(() => setError(name, { message: MAX_UPLOAD_SIZE_MESSAGE }), 0);
      }

      const compressedImage = await compressImage(file);

      if (!compressedImage) {
        field.onChange('');
        return setTimeout(() => setError(name, { message: UPLOAD_FILE_ERROR_MESSAGE }), 0);
      }

      file = compressedImage;

      const base64File = await fileToBase64(file);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_meta, base64] = base64File.split(',');

      field.onChange(base64);
      setValue('attachments.filename', file.name);
      setValue('attachments.mime-type', file.type);
      setFileUploaded(file.name);
    } catch (error) {
      setError(name, { message: UPLOAD_FILE_ERROR_MESSAGE });
      console.warn(error);
    }
  };

  return (
    <FormControl
      margin={formControlMargin}
      sx={{
        width: '100%',
        position: 'relative',
      }}
      variant="outlined"
    >
      {label && <Box mb={1}>{label}</Box>}

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <UploadButton
          ref={field.ref}
          name={field.name}
          accept={'image/*'}
          id={`helpdesk-button-file-${field.name}`}
          onChange={handleChange}
          title={isFileUploaded ?? 'Прикрепить файл'}
        />
      </Box>

      <FormHelperText sx={{ position: 'absolute', bottom: '-20px' }}>
        <Typography variant="overline">{error?.message}</Typography>
      </FormHelperText>
    </FormControl>
  );
};
