import { Theme } from '@mui/material';

export const MAX_TEXT_LENGTH_2000 = 2000;
export const REQUIRED_MESSAGE = 'Поле обязательно для заполнения';
export const UNKNOWN_MESSAGE = 'Произошла неизвестная ошибка';
export const MAX_UPLOAD_SIZE = 52428800;
export const MAX_UPLOAD_SIZE_MESSAGE = 'Размер файла не должен превышать 50 мб';
export const UPLOAD_FILE_ERROR_MESSAGE = 'Ошибка при загрузке файла';

export const inputErrorStyles = {
  [`& .MuiOutlinedInput-notchedOutline`]: {
    border: (theme: Theme) => `1px solid ${theme.palette.primary.contrastText}`,
    boxShadow: `0 0 2px 0px rgba(233, 44, 44, 0.15)`,
  },
};
