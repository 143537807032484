import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base';
import { styled } from '@mui/system';

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

type MenuItemProps = {
  isError?: boolean;
};

export const Textarea = styled(BaseTextareaAutosize, {
  shouldForwardProp: (prop) => prop !== 'isError',
})<MenuItemProps>(({ isError, theme }) => ({
  position: 'relative',
  zIndex: 1,
  boxSizing: 'border-box',

  width: '100%',
  fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: 1.5,
  padding: '8px 12px',
  borderRadius: theme.spacing(1),
  color: theme.palette.mode === 'dark' ? grey[300] : grey[900],
  backgroundColor: 'transparent',
  border: `1px solid ${
    isError ? '#FC0902' : theme.palette.mode === 'dark' ? grey[700] : grey[200]
  }`,
  boxShadow: `0px 2px 2px ${
    isError ? 'rgba(233, 44, 44, 0.15)' : theme.palette.mode === 'dark' ? grey[900] : grey[50]
  }`,

  ['&:hover']: {
    borderColor: blue[400],
  },

  ['&:focus']: {
    borderColor: '#1976D2',
  },

  // firefox
  ['&:focus-visible']: {
    outline: 0,
  },
}));
