import { Button } from '@mui/material';
import { InputHTMLAttributes, forwardRef } from 'react';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  title: string;
  isLoading?: boolean;
  startIcon?: React.ReactNode;
};

export const UploadButton = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { name, accept, id, onChange, isLoading, title, startIcon } = props;

  return (
    <>
      <input
        ref={ref}
        name={name}
        accept={accept}
        style={{ display: 'none' }}
        id={id}
        type="file"
        onChange={onChange}
      />
      <label htmlFor={id} style={{ width: '100%', pointerEvents: isLoading ? 'none' : 'initial' }}>
        <Button
          variant="outlined"
          component="span"
          startIcon={startIcon}
          disabled={isLoading}
          fullWidth
          sx={{ textTransform: 'none', fontSize: '1rem' }}
        >
          {title}
        </Button>
      </label>
    </>
  );
});

UploadButton.displayName = 'UploadButton';
