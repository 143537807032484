import { ChangeEvent } from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  OutlinedInputProps,
  FormControlProps,
  Typography,
  OutlinedInput,
} from '@mui/material';
import { useFormContext, useController, UseControllerProps } from 'react-hook-form';
import { CharacterCounter } from '../../CharacterCounter/CharacterCounter';
import { inputErrorStyles } from '../../../constants';

type Props = OutlinedInputProps & {
  name: string;
  label?: string | React.ReactNode;
  type: 'text' | 'password';
  defaultValue?: string | number;
  rules?: UseControllerProps['rules'];
  formControlMargin?: FormControlProps['margin'];
  maxTextLength?: number;
  lowerCase?: boolean;
  isReadOnly?: boolean;
};

export const FormInput = (props: Props) => {
  const {
    name,
    label,
    type,
    defaultValue,
    rules,
    formControlMargin = 'normal',
    maxTextLength,
    lowerCase = false,
    isReadOnly = false,
    ...restProps
  } = props;

  const { control } = useFormContext();

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const { ref, value, onChange, ...restField } = field;

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (lowerCase) {
      onChange(event.target.value.toLowerCase());
    } else {
      onChange(event.target.value);
    }
  };

  return (
    <FormControl
      margin={formControlMargin}
      sx={{
        width: '100%',
        position: 'relative',
        opacity: isReadOnly ? 0.6 : 'initial',
        pointerEvents: isReadOnly ? 'none' : 'auto',
      }}
      variant="outlined"
    >
      {label && <Box mb={1}>{label}</Box>}

      <OutlinedInput
        {...restField}
        value={lowerCase ? value.toLowerCase() : value}
        onChange={changeHandler}
        inputRef={ref}
        type={type}
        readOnly={isReadOnly}
        {...restProps}
        sx={error && { ...inputErrorStyles }}
      />

      <FormHelperText sx={{ position: 'absolute', bottom: '-20px' }}>
        <Typography variant="caption">{error?.message}</Typography>
      </FormHelperText>

      {maxTextLength && (
        <Box sx={{ position: 'absolute', bottom: '-20px', right: 0 }}>
          <CharacterCounter
            name={name}
            textLength={value.length}
            maxTextLength={maxTextLength}
            isError={!!error}
          />
        </Box>
      )}
    </FormControl>
  );
};
