import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from './error-page.tsx';
import { Providers } from './providers.tsx';
import './index.css';

const getEnvironmentParam = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const environmentParam = urlParams.get('env');
  const PARAMETER_NOT_PASSED = 'parameter not passed';

  if (!environmentParam) return PARAMETER_NOT_PASSED;

  type PossibleEnvParmValueType = 'development' | 'stage' | 'production';
  const possibleEnvParmValues: PossibleEnvParmValueType[] = ['development', 'stage', 'production'];
  const isParamOk = possibleEnvParmValues.includes(environmentParam as PossibleEnvParmValueType);

  return isParamOk ? environmentParam : PARAMETER_NOT_PASSED;
};

Sentry.init({
  environment: getEnvironmentParam(),

  enabled: import.meta.env.PROD,

  dsn: 'https://3d2d32381791f0cdaffd653525de4f10@sentry.q-check.pro/6',

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],

  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
  },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Providers>
      <RouterProvider router={router} />
    </Providers>
  </React.StrictMode>,
);
