import { UNKNOWN_MESSAGE } from '../../constants';
import { ErrorType, isErrorType } from '../types';
import { CreateTicketsParams, CreateTicketsResponse } from './types';
import { v4 as uuidv4 } from 'uuid';

export async function createTickets({ gRecaptchaVerify, ...params }: CreateTicketsParams) {
  const reqId = uuidv4();

  try {
    const res = await fetch(`/api/v1/tickets?g-recaptcha-verify=${gRecaptchaVerify}`, {
      headers: {
        'X-Request-Id': reqId,
      },
      method: 'POST',
      body: JSON.stringify(params),
    });

    const ticket: CreateTicketsResponse | ErrorType = await res.json();

    if (isErrorType(ticket)) {
      return { error: ticket };
    }

    return ticket;
  } catch (error) {
    const err = (error as ErrorType)?.message ?? UNKNOWN_MESSAGE;
    throw new Error(err);
  }
}
