import { load } from 'recaptcha-v3';

export const reCAPTCHA_site_key = '6LfnHwgqAAAAADg5_aBtd9nEwCfJqNKLvr-YuRxd';

export async function getRecaptcha() {
  const recaptcha = await load(reCAPTCHA_site_key);
  const token = await recaptcha.execute('contact');

  return token;
}
