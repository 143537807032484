import { useRef } from 'react';
import { type TextareaAutosizeProps } from '@mui/base';
import { Textarea } from './styled';
import {
  Box,
  FormControl,
  FormHelperText,
  FormControlProps,
  Typography,
  alpha,
} from '@mui/material';
import { useFormContext, useController, UseControllerProps } from 'react-hook-form';
import { CharacterCounter } from '../../CharacterCounter/CharacterCounter';
import { red } from '@mui/material/colors';

type Props = Omit<TextareaAutosizeProps, 'placeholder'> & {
  name: string;
  label?: string;
  defaultValue?: string;
  rules?: UseControllerProps['rules'];
  formControlMargin?: FormControlProps['margin'];
  multilinePlaceholder?: React.ReactNode;
  maxTextLength?: number;
};

export const FormTextareaAutosize = (props: Props) => {
  const ref = useRef<HTMLTextAreaElement>(null);

  const {
    name,
    label,
    defaultValue,
    rules,
    formControlMargin = 'normal',
    multilinePlaceholder,
    maxTextLength,
    ...restProps
  } = props;

  const { control } = useFormContext();

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <FormControl
      margin={formControlMargin}
      sx={{ width: '100%', position: 'relative' }}
      variant="outlined"
    >
      {label && <Box mb={1}>{label}</Box>}

      <Box sx={{ position: 'relative' }}>
        <Textarea
          ref={ref}
          aria-label="textarea"
          {...restProps}
          isError={!!error}
          onChange={field.onChange}
          value={field.value}
        />

        {!field.value && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              padding: '8.5px 14px',
              color: ({ palette }) => alpha(palette.common.black, 0.4),
              pointerEvents: 'none',
            }}
          >
            <Typography variant="body1">{multilinePlaceholder}</Typography>
          </Box>
        )}
      </Box>

      <FormHelperText sx={{ position: 'absolute', bottom: '-20px' }}>
        <Typography variant="caption" sx={{ color: red[400] }}>
          {error?.message}
        </Typography>
      </FormHelperText>

      {maxTextLength && (
        <Box sx={{ position: 'absolute', bottom: '-20px', right: 0 }}>
          <CharacterCounter
            name={name}
            textLength={field.value.length}
            maxTextLength={maxTextLength}
            isError={!!error}
          />
        </Box>
      )}
    </FormControl>
  );
};
